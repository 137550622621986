import React, { useState } from 'react';

const ThumbnailWithVideo = ({ videoId, thumbnail, title }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        setIsPlaying(true);
    };

    return (
        <div className="w-full h-full">
            {isPlaying ? (
                <iframe
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    title={title}
                    className="w-full h-full"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
            ) : (
                <div
                    className="w-full h-full bg-gray-200 relative cursor-pointer"
                    onClick={handlePlayClick}
                >
                    <img src={thumbnail} alt="Video thumbnail" className="w-full h-full object-cover" />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <button className="bg-black bg-opacity-50 text-white p-2 rounded-full">
                            ▶
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const interviews = [
    {
        title: "Dor de Cabeça com Planos de Saúde",
        interviewer: "TV Globo",
        date: "",
        description: "",
        videoId: "EkEerudIy8E",
        thumbnail: "https://img.youtube.com/vi/EkEerudIy8E/hqdefault.jpg"
    },
    {
        title: "Direito do Consumidor na Construção Civil",
        interviewer: "TV Globo",
        date: "",
        description: "",
        videoId: "8HjlK4Mds6o",
        thumbnail: "https://img.youtube.com/vi/8HjlK4Mds6o/hqdefault.jpg"
    },
    {
        title: "Venda Casada Desrespeita o Código de Defesa do Consumidor",
        interviewer: "TV Globo",
        date: "",
        description: "",
        videoId: "cc1soPLR1LI",
        thumbnail: "https://img.youtube.com/vi/cc1soPLR1LI/hqdefault.jpg"
    }
];

const InterviewSection = () => {
    return (
        <section id="entrevistas" className="bg-[#f3f4f6] py-24">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-light text-center text-gray-800 mb-12">Entrevistas</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {interviews.map((interview, index) => (
                        <div key={index} className="bg-white rounded-lg overflow-hidden shadow-lg">
                            <div className="w-full h-48 relative">
                                <ThumbnailWithVideo
                                    videoId={interview.videoId}
                                    thumbnail={interview.thumbnail}
                                    title={interview.title}
                                />
                            </div>
                            <div className="p-6">
                                <h3 className="text-xl font-semibold text-gray-800">{interview.title}</h3>
                                <p className="text-sm text-gray-500">{interview.interviewer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default InterviewSection;
