import { FaEnvelope, FaInstagram, FaYoutube } from 'react-icons/fa';

const Footer = () => (
    <footer className="bg-gray-800 text-gray-100 py-8">
        <div className="container mx-auto px-4 text-center">
            <p className="mb-4">© 2024 Alexandre Rolim de Sá. Todos os direitos reservados.</p>
            <p>Alexandre Rolim Sociedade Individual de Advocacia</p>
            <p>CNPJ n. 51.391.834/0001-06</p>
        </div>
    </footer>
);

export default Footer;
