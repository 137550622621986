import alexandreImage from '../../assets/alexandre.png';

const AboutSection = () => (
    <section id="sobre" className="relative bg-gray-800 text-gray-100">
        <div className="container mx-auto px-4 py-20 relative z-10">
            <h2 className="text-4xl font-light mb-10 text-center">Sobre nós</h2>
            <div className="flex flex-col md:flex-row items-center justify-center">
                <div className="md:w-1/3 mb-8 md:mb-0">
                    <div className="rounded-full overflow-hidden w-72 h-72 mx-auto"> {/* Aumentando o tamanho da imagem */}
                        <img
                            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
                            src={alexandreImage}
                            alt="Alexandre Rolim de Sá"
                            className="w-full h-full object-cover"
                            style={{ objectPosition: '80% 0%', transform: 'scale(1.5) translateX(-4%)' }}
                        /> 
                    </div>
                </div>
                <div className="md:w-2/3 md:pl-8">
                    <p className="text-lg mb-4">
                        Nosso escritório atua em todo o Brasil e nas mais diversas áreas do Direito, oferecendo soluções eficazes e personalizadas para nossos clientes.
                    </p>
                    <p className="text-lg mb-4">
                        Com dezenas de avaliações positivas, somos um escritório 5 estrelas no Google, devido aos excelentes resultados que entregamos.
                    </p>
                    <p className="text-lg mb-4">
                        Entre em contato conosco e descubra como podemos resolver as suas necessidades jurídicas.
                    </p>
                    <p className="text-xl font-bold mb-2" style={{ whiteSpace: 'nowrap' }}>
                        Dr. Alexandre Rolim de Sá
                    </p>
                    <p className="text-lg italic">
                        Advogado – OAB/CE 49.750
                    </p>
                    <p className="text-lg mb-8"></p> {/* Ajustando espaço */}
                    <p className="text-lg text-gray-400 italic">
                        Premiado com o selo Referência Nacional em Advocacia e Justiça pela ANCEC 🎖️
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default AboutSection;
