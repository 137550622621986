import Button from './Button';
import ImageHero from '../../assets/heroimage.avif';

const HeroSection = () => (
    <header className="hero-section relative bg-gray-800 text-gray-100">
        <div className="absolute inset-0 z-0">
            <img
                src={ImageHero}
                alt="Law office background"
                className="w-full h-full object-cover opacity-20"
            />
        </div>
        <div className="container mx-auto px-4 py-24 md:py-32 relative z-10">
            <div className="max-w-3xl mx-auto text-center">
                <h1 className="text-4xl md:text-5xl font-light mb-1">
                    Alexandre Rolim
                </h1>
                <h1 className="text-4xl md:text-5xl font-light mb-4">
                    Advocacia
                </h1>
                <h2 className="text-2xl md:text-3xl font-light mb-8">
                    Referência Nacional em Advocacia e Justiça
                </h2>
                <div className="button-wrapper">
                    <a
                        href="https://wa.me/5585985981579?text=Olá!%20Vim%20pelo%20site%20e%20gostaria%20de%20um%20atendimento%20jurídico.%20Meu%20nome%20é…"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button className="bg-gray-100 hover:bg-gray-300 text-gray-800 font-normal py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
                            Fale conosco
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    </header>
);

export default HeroSection;
