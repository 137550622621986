import Navbar from './components/ui/Navbar';
import HeroSection from './components/ui/HeroSection';
import ServicesSection from './components/ui/ServicesSection';
import AboutSection from './components/ui/AboutSection';
import ContactForm from './components/ui/ContactForm';
import Footer from './components/ui/Footer';
import TestimonialSection from './components/ui/TestimonialSection';
import CreditsFooter from './components/ui/CreditsFooter';
import { FaWhatsapp } from 'react-icons/fa';
import InterviewSection from './components/ui/InterviewSection'; // Importe o novo componente
import './index.css'; // Para incluir o CSS do botão

export default function App() {
    return (
        <div className="flex flex-col min-h-screen bg-gray-100 text-gray-800 font-sans">
            <Navbar />
            <HeroSection />
            <ServicesSection />
            <AboutSection />
            <TestimonialSection />
            <ContactForm />
            <InterviewSection /> {/* Adicione o componente aqui */}
            <Footer />
            <CreditsFooter />
            <a
                href="https://wa.me/5585985981579?text=Olá!%20Vim%20pelo%20site%20e%20gostaria%20de%20um%20atendimento%20jurídico.%20Meu%20nome%20é…"
                className="whatsapp-button"
                target="_blank"
                rel="noopener noreferrer"
            >
                <FaWhatsapp size={30} />
            </a>
        </div>
    );
}
